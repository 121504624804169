import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch added for dispatching login actions
import {
  FaHome,
  FaBlog,
  FaBriefcase,
  FaBookOpen,
  FaProjectDiagram,
  FaClipboardList,
  FaUserCircle,
} from 'react-icons/fa';
import { Menu, Transition } from '@headlessui/react';
import AuthService from '../../Appwrite/Auth'; // Adjust the path according to your file structure
import logo from '../../Assests/Image/logo.svg'; // Adjust the path according to your file structure
import { login, logout } from '../../Store/authSlice'; // Assuming you have these actions

const navItems = [
  { name: 'Home', path: '/', icon: <FaHome /> },
  { name: 'Blogs', path: '/blogs', icon: <FaBlog /> },
  { name: 'Jobs', path: '/jobs', icon: <FaBriefcase /> },
  { name: 'Learn', path: '/learn', icon: <FaBookOpen /> },
  { name: 'Projects', path: '/projects', icon: <FaProjectDiagram /> },
  { name: 'Cheatsheet', path: '/cheatsheet', icon: <FaClipboardList /> },
];

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  
  const dispatch = useDispatch(); // Use to dispatch actions
  const userData = useSelector((state) => state.auth.userData); // Redux state for user data

  // Use useEffect to listen for userData changes
  useEffect(() => {
    if (userData) {
      setIsLoggedIn(true);
      setUserName(userData.name || 'User');
    } else {
      setIsLoggedIn(false);
      setUserName('');
    }
  }, [userData]);

  // Handle login (if needed) and logout actions
  const handleLogout = async () => {
    const authService = new AuthService();
    await authService.logout();
    dispatch(logout()); // Dispatch logout action to update the store
    setIsLoggedIn(false);
    setUserName('');
    navigate("/auth/login");
  };

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img src={logo} alt="CampusCoders Logo" className="h-8" />
          <span className="text-2xl text-teal-600 ml-2 hidden lg:inline font-pirata ">
            TheCampusCoders
          </span>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex space-x-8 items-center">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center space-x-2 transition-colors duration-300 ${
                location.pathname === item.path
                  ? 'text-teal-600 border-b-2 border-teal-600'
                  : 'text-gray-800 hover:text-teal-600 hover:border-b-2 hover:border-teal-600'
              }`}
            >
              {item.icon}
              <span>{item.name}</span>
            </Link>
          ))}
        </nav>

        {/* User Profile / Login Button */}
        {isLoggedIn ? (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center space-x-2">
                <FaUserCircle className="w-8 h-8 text-teal-600" />
                <span className="hidden lg:block text-gray-800">{userName}</span>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-150"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {['Profile', 'Dashboard', 'Admin', 'Settings'].map(
                    (option) => (
                      <Menu.Item key={option}>
                        {({ active }) => (
                          <Link
                            to={`/${option.toLowerCase()}`}
                            className={`${
                              active ? 'bg-gray-100' : ''
                            } block px-4 py-2 text-gray-800`}
                          >
                            {option}
                          </Link>
                        )}
                      </Menu.Item>
                    )
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleLogout}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block w-full text-left px-4 py-2 text-gray-800`}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <Link
            to="/auth/login"
            className="text-teal-600 border border-teal-600 py-2 px-4 rounded-lg hover:bg-teal-600 hover:text-white transition-colors duration-300"
          >
            Login
          </Link>
        )}
      </div>

      {/* Mobile Navigation Menu */}
      <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-gray-100 border-t border-gray-300">
        <div className="flex text-2xl justify-around py-2">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center justify-center p-3 transition-colors duration-300 ${
                location.pathname === item.path
                  ? 'border-2 border-teal-600 rounded-full text-teal-600'
                  : 'text-gray-800 hover:text-teal-600'
              }`}
              aria-label={item.name}
              aria-current={location.pathname === item.path ? 'page' : undefined}
            >
              {item.icon}
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
